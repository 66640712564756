<template>
  <div>
    <div class="toolbar py-5 py-lg-15">
      <div class="container-xxl d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3">
          <h1 class="text-white">Proposals</h1>

          <px-new-breadcrumbs :breadcrumbs="breadcrumbs" />
        </div>

        <div class="align-self-start">
          <router-link
            :to="{ name: 'proposals-archive' }"
            class="btn btn-sm btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary"
          >
            <i class="bi bi-archive me-1" />
            Archive
          </router-link>
        </div>
      </div>
    </div>

    <div class="container-xxl pb-10">
      <div class="content flex-row-fluid">
        <pxs-proposals
          :current-manager-id="user.id"
          :excluded-headers="['construction']"
          proposal-status="not.Signed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PxNewBreadcrumbs from '@/components/elements/PxNewBreadcrumbs';

import PxsProposals from '@/sections/PxsProposals';

export default {
  components: {
    PxNewBreadcrumbs,
    PxsProposals,
  },
  data() {
    return {
      breadcrumbs: [
        {
          id: '1001',
          title: 'Home',
          to: 'dashboard',
        },
        {
          id: '1002',
          title: 'Proposals',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
};
</script>
